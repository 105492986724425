<template>
    <div>
        <!-- Undo & Save Buttons -->
        <portal
            v-if="activated"
            to="client-edit-actions"
        >
            <!-- TODO: Detect if a program has been removed, enable resetting the collection. -->
            <b-btn
                variant="light"
                class="btn-min-width mr-3"
                :disabled="false"
                @click="programs.reset()"
            >
                {{ $t('common.actions.undoChanges') }}
            </b-btn>

            <btn-resource
                variant="primary"
                class="btn-min-width"
                :resource="programs"
                :disabled="false"
                @click="savePrograms()"
            >
                {{ $t('common.actions.saveChanges') }}
            </btn-resource>
        </portal>

        <!-- Add Program Card -->
        <b-card body-class="d-flex align-items-center">
            <h4>
                {{ $t('clients.edit.programs.nav') }}
            </h4>

            <div
                v-b-modal="'modal-add-program'"
                class="ml-auto cursor-pointer"
                tabindex="-1"
            >
                {{ $t('common.actions.addPrograms') }}

                <btn-square
                    class="ml-3"
                    variant="dark"
                >
                    <fa icon="plus" />
                </btn-square>
            </div>

            <media-modal
                id="modal-add-program"
                :types="programs.types"
                :privacies="programs.privacies"
                :initial-selection="programs.models"
                :no-items-text="$t('programs.messages.noPrivateProgramsFound')"
                @add-media="programs.replace($event)"
            />
        </b-card>

        <!-- Programs Cards -->
        <wait-for-resource
            :resource="programs"
            class="mt-3 card-stack"
        >
            <b-card
                v-for="program of programs.models"
                :key="program.id"
                body-class="d-flex align-items-center"
            >
                <h4> {{ program.title }} </h4>

                <btn-square
                    variant="light"
                    class="ml-auto text-danger"
                    @click="programs.remove(program)"
                >
                    <fa :icon="['fac', 'trash']" />
                </btn-square>
            </b-card>
        </wait-for-resource>
    </div>
</template>

<script>
import {Posts} from '@/models/Post';
import {Client} from '@/models/Client';
import PostType from '@/library/enumerations/PostType';
import MediaModal from '@/components/media/MediaModal';
import PostPrivacy from '@/library/enumerations/PostPrivacy';
import KeptAlive from '@/components/common/mixins/KeptAlive';
import TryCatchWithToast from '@/components/common/mixins/TryCatchWithToast';
import ConfirmLeaveUnsaved from '@/components/common/mixins/ConfirmLeaveUnsaved';

export default {
    name: 'ClientEditPrograms',
    components: {MediaModal},
    mixins: [ConfirmLeaveUnsaved, KeptAlive, TryCatchWithToast],
    props: {
        client: {
            type: Client,
            required: true,
        },
    },
    data: function() {
        return {
            programs: new Posts([], {
                endpoint: 'related-posts',
                routePrefix: `users/${this.client.user.id}`,
            }),
        };
    },
    computed: {
        hasUnsavedChanges() {
            return !!this.programs.changed();
        },
    },
    async created() {
        // Fetch only programs that are directly related to the client.
        this.programs.types = [PostType.PROGRAM];

        this.programs.privacies = [PostPrivacy.RELATED_TO_POST];

        await this.programs.fetch();
    },
    methods: {
        savePrograms() {
            this.tryCatchWithToast(async() => {
                await this.programs.save();
            }, {
                success: this.$t('clients.messages.saveProgramsSuccess'),
            });
        },
    },
};
</script>
